import { useLocation } from "react-router-dom";
import { Button, Card, Form, Input, Loading } from "react-daisyui";
import * as Yup from "yup";
import { Formik } from "formik";
import { useLogin, useSignup } from "../hooks/useAuthentication";
import showToast from "../components/Toast";

/**
 * A React component for logging in a user.
 *
 * @component
 *
 * @returns {React.ReactElement} The React element representing the component.
 */
export default function Authentication() {
  const location = useLocation();
  const operation = location.pathname.split("/")[1];

  // Initial credentials values
  const initialCredentialsValues = {
    email: "",
    password: "",
  };

  // Credentials Yup schema
  const credentialsYupSchema = Yup.object().shape({
    email: Yup.string()
      .required("This field is required.")
      .max(128, "This field must be less than or equal to 128 characters."),
    password: Yup.string()
      .required("This field is required.")
      .min(8, "This field must be at least 8 characters long.")
      .max(128, "This field must be at most 128 characters long.")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
      ),
  });

  // Initial login values
  const initialLoginValues = initialCredentialsValues;

  // Login Yup schema
  const loginYupSchema = credentialsYupSchema;

  // Initial signup values
  const initialSignupValues = {
    ...initialCredentialsValues,
    confirmPassword: "",
  };

  // Signup Yup schema
  const signupYupSchema = credentialsYupSchema.concat(
    Yup.object().shape({
      confirmPassword: Yup.string()
        .required("This field is required.")
        .oneOf([Yup.ref("password"), null], "Passwords must match."),
    }),
  );

  // Login mutation
  const login = useLogin();

  // Handle login
  const handleLogin = (values, { resetForm }) => {
    login.mutate(values, {
      // On success, reset the form
      onSuccess: () => {
        resetForm();
      },
      // On error, display error notification
      onError: (error) => {
        showToast({ text: error.response.data.error, color: "error" });
        console.log(error);
      },
    });
  };

  // Signup mutation
  const signup = useSignup();

  // Handle signup
  const handleSignup = (values, { resetForm }) => {
    signup.mutate(values, {
      // On success, reset the form
      onSuccess: () => {
        resetForm();
      },
      // On error, display error notification
      onError: (error) => {
        showToast({ text: error.response.data.error, color: "error" });
        console.log(error);
      },
    });
  };

  return (
    <Formik
      initialValues={
        operation === "login"
          ? initialLoginValues
          : operation === "signup" && initialSignupValues
      }
      enableReinitialize
      validationSchema={
        operation === "login"
          ? loginYupSchema
          : operation === "signup" && signupYupSchema
      }
      onSubmit={
        operation === "login"
          ? handleLogin
          : operation === "signup" && handleSignup
      }
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <Form noValidate onSubmit={handleSubmit} className="m-auto pt-3">
          <Card className="shadow-xl w-full md:w-96">
            <Card.Body>
              <Card.Title tag="h2">
                {operation === "login"
                  ? "Log in"
                  : operation === "signup" && "Sign up"}
              </Card.Title>

              {/* Email Address */}
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Email Address</span>
                </label>
                <Input
                  color={touched.email && errors.email ? "error" : ""}
                  name="email"
                  type="email"
                  placeholder="example@mail.com"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.email && errors.email && (
                  <label className="label">
                    <span className="label-text-alt text-error">
                      {errors.email}
                    </span>
                  </label>
                )}
              </div>

              {/* Password */}
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Password</span>
                </label>
                <Input
                  color={touched.password && errors.password ? "error" : ""}
                  name="password"
                  type="password"
                  placeholder="********"
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.password && errors.password && (
                  <label className="label">
                    <span className="label-text-alt text-error">
                      {errors.password}
                    </span>
                  </label>
                )}
              </div>

              {/* Confirm Password */}
              {operation === "signup" && (
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">Confirm Password</span>
                  </label>
                  <Input
                    color={
                      touched.confirmPassword && errors.confirmPassword
                        ? "error"
                        : ""
                    }
                    name="confirmPassword"
                    type="password"
                    placeholder="********"
                    value={values.confirmPassword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.confirmPassword && errors.confirmPassword && (
                    <label className="label">
                      <span className="label-text-alt text-error">
                        {errors.confirmPassword}
                      </span>
                    </label>
                  )}
                </div>
              )}

              {/* Submit Button */}

              <Card.Actions className="justify-end mt-3">
                {/* Submit Button */}
                <Button
                  type="submit"
                  color="primary"
                  disabled={login.isPending || signup.isPending}
                >
                  {login.isPending || signup.isPending ? (
                    <>
                      <Loading variant="dots" />
                      {operation === "login"
                        ? "Logging in"
                        : operation === "signup" && "Signing up"}
                    </>
                  ) : operation === "login" ? (
                    "Log in"
                  ) : (
                    operation === "signup" && "Sign up"
                  )}
                </Button>
              </Card.Actions>
            </Card.Body>
          </Card>
        </Form>
      )}
    </Formik>
  );
}
