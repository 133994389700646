import { Link, useLocation } from "react-router-dom";
import { Button, Dropdown, Navbar, Swap, useTheme } from "react-daisyui";
import { FaMoon, FaSun } from "react-icons/fa6";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";

const Header = () => {
  const location = useLocation();
  const operation = location.pathname.split("/")[1];

  const { logout } = useLogout();
  const { user } = useAuthContext();

  const { setTheme } = useTheme();

  const handleThemeChange = (event) => {
    const theme = event.target.checked ? "dark" : "light";
    document.getElementsByTagName("html")[0].setAttribute("data-theme", theme);
    setTheme(theme);
  };

  return (
    <Navbar className="shadow rounded-xl">
      <div className="flex-1">
        <Button tag="a" className="text-xl normal-case gap-0" color="ghost">
          <span className="text-primary">Workout</span>Bud
        </Button>
      </div>
      <div className="flex-none gap-2">
        <Swap
          rotate
          onElement={<FaMoon className="text-2xl mx-3" />}
          offElement={<FaSun className="text-2xl mx-3" />}
          onChange={handleThemeChange}
        />

        {user ? (
          <Dropdown end>
            <Button
              tag="label"
              tabIndex={0}
              color="neutral"
              className="avatar"
              shape="circle"
            >
              {user.email.substring(0, 2).toUpperCase()}
            </Button>
            <Dropdown.Menu className="w-52 menu-sm mt-3 z-[1] p-2">
              <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : operation === "signup" ? (
          <Link to="/login">
            <Button color="primary" variant="outline">
              Log in
            </Button>
          </Link>
        ) : (
          operation === "login" && (
            <Link to="/signup">
              <Button color="primary" variant="outline">
                Sign up
              </Button>
            </Link>
          )
        )}
      </div>
    </Navbar>
  );
};

export default Header;
