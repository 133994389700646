import { useState } from "react";
import { Button, Input, Loading, Modal } from "react-daisyui";
import { FaPlus, FaPen } from "react-icons/fa6";
import * as Yup from "yup";
import { Formik } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import { useAddWorkout, useEditWorkout } from "../hooks/useWorkouts";
import showToast from "./Toast";
import { exercises } from "../data/exercises";

/**
 * A React component for adding or editing a workout.
 *
 * @component
 *
 * @param {Object} props The component props.
 * @param {string} props.operation The operation to perform. Either "add" or "edit".
 * @param {Object} props.workout The workout to edit. Only used if operation is "edit".
 *
 * @returns {React.ReactElement} The React element representing the component.
 */
export default function AddEditWorkout({ operation, workout }) {
  const queryClient = useQueryClient();

  // Modal visibility state
  const [visible, setVisible] = useState(false);

  // Toggle modal visibility
  const toggleVisible = () => {
    setVisible(!visible);
  };

  // Initial workout values
  const initialWorkoutValues = {
    title: workout?.title || "",
    load: workout?.load || 0,
    reps: workout?.reps || 0,
    duration: workout?.duration || 0,
  };

  // Initial add workout values
  const initialAddWorkoutValues = initialWorkoutValues;

  // Initial edit workout values
  const initialEditWorkoutValues = {
    ...initialWorkoutValues,
    _id: workout?._id || "",
  };

  // Workout Yup schema
  const workoutYupSchema = Yup.object().shape({
    title: Yup.string()
      .required("This field is required.")
      .max(128, "This field must be at most 128 characters long."),
    load: Yup.number()
      .required("This field is required.")
      .min(0, "This field must be greater than or equal to 0.")
      .max(1000, "This field must be less than or equal to 1000."),
    reps: Yup.number()
      .required("This field is required.")
      .min(0, "This field must be greater than or equal to 0.")
      .max(1000, "This field must be less than or equal to 1000."),
    duration: Yup.number()
      .required("This field is required.")
      .min(0, "This field must be greater than or equal to 0.")
      .max(1000, "This field must be less than or equal to 1000."),
  });

  // Add workout mutation
  const addWorkout = useAddWorkout();

  // Handle add workout
  const handleAddWorkout = (values, { resetForm }) => {
    addWorkout.mutate(values, {
      // On success, display success notification, close the modal, reset the form, and invalidate query to refetch data
      onSuccess: () => {
        showToast({ text: "Workout successfully added!", color: "success" });
        setVisible(false);
        resetForm();
        queryClient.invalidateQueries({ queryKey: ["workouts"] });
      },
      // On error, display error notification
      onError: (error) => {
        showToast({ text: "Failed to add workout!", color: "error" });
        console.log(error);
      },
    });
  };

  // Edit workout mutation
  const editWorkout = useEditWorkout();

  // Handle edit workout
  const handleEditWorkout = (values, { resetForm }) => {
    editWorkout.mutate(values, {
      // On success, display success notification, close the modal, reset the form, and invalidate query to refetch data
      onSuccess: () => {
        showToast({ text: "Workout successfully edited!", color: "success" });
        setVisible(false);
        resetForm();
        queryClient.invalidateQueries({ queryKey: ["workouts"] });
      },
      // On error, display error notification
      onError: (error) => {
        showToast({ text: "Failed to edit workout!", color: "error" });
        console.log(error);
      },
    });
  };

  return (
    <>
      {/* Open Modal Button */}
      <Button
        onClick={toggleVisible}
        color={
          operation === "add" ? "primary" : operation === "edit" && "warning"
        }
      >
        {operation === "add" ? <FaPlus /> : operation === "edit" && <FaPen />}
      </Button>

      <Formik
        initialValues={
          operation === "add"
            ? initialAddWorkoutValues
            : operation === "edit" && initialEditWorkoutValues
        }
        enableReinitialize
        validationSchema={workoutYupSchema}
        onSubmit={
          operation === "add"
            ? handleAddWorkout
            : operation === "edit" && handleEditWorkout
        }
      >
        {({
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Modal.Legacy open={visible}>
              {/* Close Modal Button */}
              <Button
                type="button"
                size="sm"
                color="ghost"
                shape="circle"
                className="absolute right-2 top-2"
                onClick={() => {
                  resetForm();
                  toggleVisible();
                }}
              >
                ✕
              </Button>

              <Modal.Header className="font-bold mb-6">
                {operation === "add" ? "Add " : operation === "edit" && "Edit "}
                Workout
              </Modal.Header>

              <Modal.Body>
                {/* Exercise */}
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">
                      Exercize<span className="ml-1 text-error">*</span>
                    </span>
                  </label>
                  <Input
                    color={touched.title && errors.title ? "error" : ""}
                    name="title"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.title}
                    list="workouts"
                  />
                  <datalist id="workouts">
                    {exercises
                      .toSorted((a, b) => a.workout.localeCompare(b.workout))
                      .map((workout) => (
                        <option key={workout.id} value={workout.workout}>
                          {workout.workout}
                        </option>
                      ))}
                  </datalist>
                  {touched.title && errors.title && (
                    <label className="label">
                      <span className="label-text-alt text-error">
                        {errors.title}
                      </span>
                    </label>
                  )}
                </div>

                {/* Load (in kg) */}
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">Load (in kg)</span>
                  </label>
                  <Input
                    color={touched.load && errors.load ? "error" : ""}
                    name="load"
                    type="number"
                    min={0}
                    max={1000}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.load}
                  />
                  {touched.load && errors.load && (
                    <label className="label">
                      <span className="label-text-alt text-error">
                        {errors.load}
                      </span>
                    </label>
                  )}
                </div>

                {/* Number of Repetitions */}
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">Number of Repetitions</span>
                  </label>
                  <Input
                    color={touched.reps && errors.reps ? "error" : ""}
                    name="reps"
                    type="number"
                    min={0}
                    max={1000}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.reps}
                  />
                  {touched.reps && errors.reps && (
                    <label className="label">
                      <span className="label-text-alt text-error">
                        {errors.reps}
                      </span>
                    </label>
                  )}
                </div>

                {/* Duration (in minutes) */}
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">Duration (in minutes)</span>
                  </label>
                  <Input
                    color={touched.duration && errors.duration ? "error" : ""}
                    name="duration"
                    type="number"
                    min={0}
                    max={1000}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.duration}
                  />
                  {touched.duration && errors.duration && (
                    <label className="label">
                      <span className="label-text-alt text-error">
                        {errors.duration}
                      </span>
                    </label>
                  )}
                </div>
              </Modal.Body>

              <Modal.Actions>
                {/* Submit Button */}
                <Button
                  type="submit"
                  color="primary"
                  disabled={addWorkout.isPending || editWorkout.isPending}
                >
                  {addWorkout.isPending || editWorkout.isPending ? (
                    <>
                      <Loading variant="dots" />
                      {operation === "add"
                        ? "Adding Workout"
                        : operation === "edit" && "Editing Workout"}
                    </>
                  ) : operation === "add" ? (
                    "Add Workout"
                  ) : (
                    operation === "edit" && "Edit Workout"
                  )}
                </Button>
              </Modal.Actions>
            </Modal.Legacy>
          </form>
        )}
      </Formik>
    </>
  );
}
