import { useState } from "react";
import { Button, Loading, Modal } from "react-daisyui";
import { FaTrash } from "react-icons/fa6";
import { useQueryClient } from "@tanstack/react-query";
import { useDeleteWorkout } from "../hooks/useWorkouts";
import showToast from "./Toast";

/**
 * A React component for deleting a workout.
 *
 * @component
 *
 * @param {Object} props The component props.
 * @param {Object} props.workout The workout to delete.
 *
 * @returns {React.ReactElement} The React element representing the component.
 */
export default function DeleteWorkout({ workout }) {
  const queryClient = useQueryClient();

  // Modal visibility state
  const [visible, setVisible] = useState(false);

  // Toggle modal visibility
  const toggleVisible = () => {
    setVisible(!visible);
  };

  // Delete workout mutation
  const deleteWorkout = useDeleteWorkout();

  // Handle delete workout
  const handleDeleteWorkout = () => {
    deleteWorkout.mutate(workout, {
      // On success, display success notification, close the modal, and invalidate query to refetch data
      onSuccess: () => {
        showToast({ text: "Workout successfully deleted!", color: "success" });
        setVisible(false);
        queryClient.invalidateQueries({ queryKey: ["workouts"] });
      },
      // On error, display error notification
      onError: (error) => {
        showToast({ text: "Failed to delete workout!", color: "error" });
        console.log(error);
      },
    });
  };

  return (
    <>
      {/* Open Modal Button */}
      <Button onClick={toggleVisible} color="error">
        <FaTrash />
      </Button>

      <Modal.Legacy open={visible}>
        {/* Close Modal Button */}
        <Button
          size="sm"
          color="ghost"
          shape="circle"
          className="absolute right-2 top-2"
          onClick={toggleVisible}
        >
          ✕
        </Button>

        <Modal.Header className="font-bold mb-6">Delete Workout</Modal.Header>

        <Modal.Body>Are you sure you want to delete this workout?</Modal.Body>

        <Modal.Actions>
          {/* Submit Button */}
          <Button
            type="submit"
            color="error"
            onClick={handleDeleteWorkout}
            disabled={deleteWorkout.isPending}
          >
            {deleteWorkout.isPending ? (
              <>
                <Loading variant="dots" /> Deleting Workout
              </>
            ) : (
              "Delete Workout"
            )}
          </Button>
        </Modal.Actions>
      </Modal.Legacy>
    </>
  );
}
