import WorkoutDetailsSkeleton from "../components/WorkoutDetailsSkeleton";
import WorkoutDetails from "../components/WorkoutDetails";
import AddEditWorkout from "../components/AddEditWorkout";
import { useGetWorkouts } from "../hooks/useWorkouts";
// import DataSeeder from "../components/DataSeeder";

const Home = () => {
  // Get Workouts
  const workouts = useGetWorkouts();

  return (
    <div className="w-full max-w-xl">
      <div className="flex items-center my-3 mx-4">
        <div className="mr-auto text-2xl font-bold">Workouts</div>
        {/* <DataSeeder workouts={workouts} /> */}
        <AddEditWorkout operation="add" />
      </div>

      {workouts.isPending &&
        Array(5)
          .fill()
          .map((_, index) => <WorkoutDetailsSkeleton key={index} />)}

      {workouts.data?.map((workout) => (
        <WorkoutDetails key={workout._id} workout={workout} />
      ))}
    </div>
  );
};

export default Home;
