import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";

/**
 * A custom hook for signing up a user.
 *
 * @hook
 *
 * @returns {Object} An object containing the results of the mutation.
 */
export const useSignup = () => {
  const { dispatch } = useAuthContext();

  const { mutate, mutateAsync, isPending, isSuccess, isError, data, error } =
    useMutation({
      mutationFn: async (credentials) => {
        return axios.post("/api/user/signup", credentials);
      },
      onSuccess: (data) => {
        localStorage.setItem("user", JSON.stringify(data.data));
        dispatch({ type: "LOGIN", payload: data.data });
      },
    });

  return { mutate, mutateAsync, isPending, isSuccess, isError, data, error };
};

/**
 * A custom hook for logging in a user.
 *
 * @hook
 *
 * @returns {Object} An object containing the results of the mutation.
 */
export const useLogin = () => {
  const { dispatch } = useAuthContext();

  const { mutate, mutateAsync, isPending, isSuccess, isError, data, error } =
    useMutation({
      mutationFn: async (credentials) => {
        return axios.post("/api/user/login", credentials);
      },
      onSuccess: (data) => {
        localStorage.setItem("user", JSON.stringify(data.data));
        dispatch({ type: "LOGIN", payload: data.data });
      },
    });

  return { mutate, mutateAsync, isPending, isSuccess, isError, data, error };
};
