import { Card } from "react-daisyui";
import { formatDistanceToNow } from "date-fns";
import AddEditWorkout from "../components/AddEditWorkout";
import DeleteWorkout from "./DeleteWorkout";

const WorkoutDetails = ({ workout }) => {
  return (
    <Card className="shadow-xl mb-3">
      <Card.Body className="flex flex-row items-center gap-x-8">
        <div className="flex flex-col gap-y-2 mr-auto">
          <Card.Title tag="h2">{workout.title}</Card.Title>
          <div>
            {/* Load */}
            <div>
              <strong>Load: </strong>
              {workout.load} kilograms
            </div>

            {/* Repetitions */}
            <div>
              <strong>Repetitions: </strong>
              {workout.reps}
            </div>

            {/* Duration */}
            <div>
              <strong>Duration: </strong>
              {workout.duration} minutes
            </div>

            {/* Created At */}
            <div className="font-light text-sm mt-2">
              {formatDistanceToNow(new Date(workout.createdAt), {
                addSuffix: true,
              })}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-y-1">
          <AddEditWorkout operation="edit" workout={workout} />
          <DeleteWorkout workout={workout} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default WorkoutDetails;
