import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useAuthContext } from "./hooks/useAuthContext";
import Home from "./pages/Home";
import Authentication from "./pages/Authentication";
import Header from "./components/Header";

const queryClient = new QueryClient();

function App() {
  const { user } = useAuthContext();

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <div className="flex flex-col items-center min-h-screen container mx-auto p-3">
            <Header />
            <Routes>
              <Route
                path="/"
                element={user ? <Home /> : <Navigate to="/login" />}
              />
              <Route
                path="/login"
                element={!user ? <Authentication /> : <Navigate to="/" />}
              />
              <Route
                path="/signup"
                element={!user ? <Authentication /> : <Navigate to="/" />}
              />
            </Routes>
          </div>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;
