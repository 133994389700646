export const exercises = [
  { id: 1, workout: "Push-ups", type: "Repetition" },
  { id: 2, workout: "Sit-ups", type: "Repetition" },
  { id: 3, workout: "Plank", type: "Duration" },
  { id: 4, workout: "Squats", type: "Repetition" },
  { id: 5, workout: "Running", type: "Duration" },
  { id: 6, workout: "Lunges", type: "Repetition" },
  { id: 7, workout: "Burpees", type: "Repetition" },
  { id: 8, workout: "Jumping Jacks", type: "Repetition" },
  { id: 9, workout: "Cycling", type: "Duration" },
  { id: 10, workout: "Yoga", type: "Duration" },
  { id: 11, workout: "Deadlifts", type: "Repetition" },
  { id: 12, workout: "Mountain Climbers", type: "Repetition" },
  { id: 13, workout: "Swimming", type: "Duration" },
  { id: 14, workout: "Box Jumps", type: "Repetition" },
  { id: 15, workout: "Rowing", type: "Duration" },
  { id: 16, workout: "Bench Press", type: "Repetition" },
  { id: 17, workout: "Climbing Stairs", type: "Duration" },
  { id: 18, workout: "Kettlebell Swings", type: "Repetition" },
  { id: 19, workout: "CrossFit", type: "Repetition" },
  { id: 20, workout: "Hiking", type: "Duration" },
  { id: 21, workout: "Pull-ups", type: "Repetition" },
  { id: 22, workout: "Jump Rope", type: "Duration" },
  { id: 23, workout: "Pilates", type: "Duration" },
  { id: 24, workout: "Leg Press", type: "Repetition" },
  { id: 25, workout: "HIIT", type: "Repetition" },
  { id: 26, workout: "Circuit Training", type: "Repetition" },
  { id: 27, workout: "Elliptical Trainer", type: "Duration" },
  { id: 28, workout: "Sprints", type: "Repetition" },
  { id: 29, workout: "TRX Suspension Training", type: "Repetition" },
  { id: 30, workout: "Kickboxing", type: "Repetition" },
  { id: 31, workout: "Zumba", type: "Duration" },
  { id: 32, workout: "Barre", type: "Duration" },
];
