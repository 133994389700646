import { Button, Card, Skeleton } from "react-daisyui";
import { FaPen, FaTrash } from "react-icons/fa6";

export default function WorkoutDetailsSkeleton() {
  return (
    <Card className="shadow-xl mb-3">
      <Card.Body className="flex flex-row items-center gap-x-8">
        <div className="flex flex-col gap-y-2 grow">
          <Card.Title tag="h2">
            <Skeleton className="h-7 w-full" />
          </Card.Title>
          <div>
            <Skeleton className="h-5 w-6/12 mb-1" />
            <Skeleton className="h-5 w-9/12 mb-1" />
            <Skeleton className="h-5 w-8/12 mb-1" />
            <Skeleton className="h-5 w-7/12 mb-1" />
          </div>
        </div>

        <div className="flex flex-col gap-y-1">
          <Button disabled>
            <FaPen />
          </Button>
          <Button disabled>
            <FaTrash />
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}
