import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";

/**
 * A function for getting workouts.
 *
 * @function
 *
 * @returns {Object} An object containing the results of the query.
 */
export const useGetWorkouts = () => {
  const { user } = useAuthContext();

  const queryFn = async () => {
    return axios
      .get("/api/workouts", {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((res) => res.data);
  };

  const { isPending, isFetching, isSuccess, isError, data, error } = useQuery({
    queryKey: ["workouts"],
    queryFn: queryFn,
    enabled: !!user,
  });

  return { isPending, isFetching, isSuccess, isError, data, error };
};

/**
 * A function for adding a workout.
 *
 * @function
 *
 * @returns {Object} An object containing the results of the mutation.
 */
export const useAddWorkout = () => {
  const { user } = useAuthContext();

  const { mutate, mutateAsync, isPending, isSuccess, isError, data, error } =
    useMutation({
      mutationFn: async (workout) => {
        return axios.post("/api/workouts", workout, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
      },
    });

  return { mutate, mutateAsync, isPending, isSuccess, isError, data, error };
};

/**
 * A function for editing a workout.
 *
 * @function
 *
 * @returns {Object} An object containing the results of the mutation.
 */
export const useEditWorkout = () => {
  const { user } = useAuthContext();

  const { mutate, mutateAsync, isPending, isSuccess, isError, data, error } =
    useMutation({
      mutationFn: async (workout) => {
        return axios.patch("/api/workouts/" + workout._id, workout, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
      },
    });

  return { mutate, mutateAsync, isPending, isSuccess, isError, data, error };
};

/**
 * A function for deleting a workout.
 *
 * @function
 *
 * @returns {Object} An object containing the results of the mutation.
 */
export const useDeleteWorkout = () => {
  const { user } = useAuthContext();

  const { mutate, mutateAsync, isPending, isSuccess, isError, data, error } =
    useMutation({
      mutationFn: async (workout) => {
        return axios.delete("/api/workouts/" + workout._id, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
      },
    });

  return { mutate, mutateAsync, isPending, isSuccess, isError, data, error };
};
