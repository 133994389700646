import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

export default function showToast({ text, color = "info" }) {
  if (!text) {
    switch (color) {
      case "success":
        text = "Success";
        break;
      case "warning":
        text = "Warning";
        break;
      case "error":
        text = "Error";
        break;
      default:
        text = "Info";
        break;
    }
  }

  switch (color) {
    case "success":
      color = "bg-success";
      break;
    case "warning":
      color = "bg-warning";
      break;
    case "error":
      color = "bg-error";
      break;
    default:
      color = "bg-info";
      break;
  }

  Toastify({
    text: text,
    close: true,
    gravity: "bottom",
    className: `bg-none ${color}`,
  }).showToast();
}
